import React from 'react';
import { ModalBackground, VideoContainer } from './elements';
import { Close } from './elements';
import colors from 'src/style/colors';
import { mediaQueries } from 'src/style/breakpoints';
import { useMediaQueries } from '../../utils/MediaQueries';

const ModalVideo = ({ onClose, isOpen, link }) => {
  const mqs = useMediaQueries();
  return (
    <ModalBackground onClick={onClose} isOpen={isOpen}>
      <button
        onClick={onClose}
        css={{
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          position: 'absolute',
          top: 40,
          right: 40,
          width: 60,
          height: 60,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          borderRadius: '50%',
          WebkitMaskImage: '-webkit-radial-gradient(white, black)',
          transitionProperty: 'all',
          transitionDuration: '0.3s',
          transitionTimingFunction: 'ease-in-out',
          [mediaQueries.phoneOnly]: {
            top: 10,
            right: 10,
          },
          '&:before': {
            content: '""',
            backgroundColor: colors.purple,
            position: 'absolute',
            display: 'block',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            transform: 'scaleX(0)',
            transformOrigin: '0 50%',
            transitionProperty: 'transform',
            transitionDuration: '0.3s',
            transitionTimingFunction: 'ease-in-out',
            zIndex: 1,
          },
          '&:hover:before': {
            transform: 'scaleX(1)',
          },
        }}
      >
        <Close colorFill={colors.white} />
      </button>
      <VideoContainer mqs={mqs}>
        <iframe
          title="youtube-video"
          style={{ height: '100%', width: '100%' }}
          src={link + '?autoplay=1&rel=0&enablejsapi=1'}
          allow="autoplay"
          frameBorder="0"
          allowFullScreen
        />
      </VideoContainer>
    </ModalBackground>
  );
};

export default ModalVideo;
